.footer {
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100%;
	min-height: 38px;
	background: $black;
	color: #fff;
	z-index: 999;
	.copyright {
		font-weight: 600;
		font-size: 10px;
		margin: 13px 0px 0px;
	}
	.social {
		margin-top: 9px;
		margin-bottom: 5px;
		li {
			display: inline-block;
			margin-left: 10px;
			a {
				font-size: 16px;
				color: #fff;
				&:hover {
					color: $red;
				}
			}
		}
	}
}

.footer2 {
	color: #313131;
	background: #ebebeb;
	padding-top: 40px;
	position: relative;
	.footer2-wrap {
		max-width: 900px;
		margin: 0 auto;
	}
	.logo {
		margin-bottom: 25px;
		max-width: 100%;
		width: 220px;
		@include sm2down {
			margin-top: 20px;
		}
	}
	.text1 {
		margin-bottom: 15px;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.4;
	}
	.social {
		margin-top: 0px;
		margin-bottom: 20px;
		li {
			display: inline-block;
			margin-left: 10px;
			a {
				font-size: 16px;
				color: $black;
				&:hover {
					color: $red;
				}
			}
		}
	}
	.info-block {
		display: flex;
		align-items: center;
		line-height: 0;
		background-color: #6c6c6c;
		color: #fff;
		margin-bottom: 50px;
		@include sm2down {
			margin-bottom: 10px;
		}
		i.icon {
			display: flex;
			width: 60px;
			height: 60px;
			background-color: #959595;
			color: $black;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			margin-right: 10px;
			@include md2down {
				width: 50px;
				height: 50px;
			}
			&.icon-phone {
				background-image: url(../img/icon-phone.svg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: 20px;
			}
		}
		i.fa {
			margin-right: 5px;
			transform:rotate(90deg);
		}
		span {
			line-height: 1.3;
			font-size: 10px;
		}
		strong {
			display: block;
			font-size: 20px;
			font-weight: 600;
			@include md {
				font-size: 18px;
			}
		}
	}
	.menu {
		margin-top: 20px;
		li {
			margin-bottom: 10px;
			a {
				color: inherit;
				font-size: 14px;
				font-weight: 500;
				&:hover {
					color: $red;
				}
			}
		}
	}
	.copyright {
		text-align: center;
		background-color: #fff;
		color: #313131;
		padding-top: 10px;
		font-size: 12px;
		font-weight: 600;
		p {
			margin-bottom: 10px;
		}
	}
	.text-collar {
		color: #959595;
		border-bottom: 1px solid #d7d7d7;
		font-size: 10px;
		padding-bottom: 5px;
		font-style: italic;
	}
	.collar-wrap {
		padding: 0 40px;
	}
	.img-collar {
		max-width: 100%;
	    margin-left: 10px;
	    margin-right: 10px;
		margin-bottom: 15px;
		height: 50px;
		@include sm2down {
			height: 40px;
		}
		@include xs {
			height: 30px;
		}
	}
	.text-network {
		color: $black;
		font-size: 13px;
		font-weight: bold;
		margin-bottom: 0px;
		letter-spacing: 2.5px;
		margin-bottom: 5px;
	}
	.text-inspiration {
		color: $black;
		font-size: 20px;
		font-style: italic;
	}
	h4 {
		font-size: 18px;
		color: $black;
		font-weight: bold;
		margin-bottom: 15px;
		margin-top: 20px;
	}
	.img-paypal {
		margin-bottom: 20px;
	}
}

.newsletter {
	color: #fff;
	position: relative;
	padding: 0px 0 50px;
	background: #fff;
	@include sm2down {
		padding-bottom: 0px;
	}
	&::before {
		content: '';
		position: absolute;
		top: 20px;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #ebebeb;
	}
	.text1 {
		font-size: 14px;
		margin: 0;
		color: $black;
	}
	.text2 {
		font-size: 26px;
		margin: 0;
		color: $black;
		strong {
			font-weight: 600;
		}
	}
	.input {
		@include sm2down {
			margin-top: 15px;
		}
	}
	.submit {
		@include lg2up {
			min-width: 150px;
		}
	}
}