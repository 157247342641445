/* Init */
@import 'functions/var';
@import 'functions/responsive';
@import 'reset';

/* Elements */
@import 'elements/header';
@import 'elements/footer';


/* Pages */

#main {
	@include sm2down {
		margin-top: 95px;
	}
}

.enquire-ticky {
	position: fixed;
    top: 40%;
    left: 12px;
    background: #8d181e;
    color: #fff;
    text-transform: uppercase;
    border: none;
    transform: translateX(-50%) rotate(-90deg);
	transform-origin: center;
	height: 35px;
	cursor: pointer;
	z-index: 9;
}

/* home */
.home-banner-wrap {
	padding-top: 100vh;
	position: relative;
	min-height: 600px;
	@media (max-width: 1024px) {
		min-height: auto;
		padding-top: 0;
	}
	.home-banner-bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		border-top: 1px solid #fff;
		padding-bottom: 30px;

		@include sm2down {
			position: relative;
			background: rgba(0,0,0,0.8);
			border-top: none;
		}
		.text-block1 {
			margin-top: 20px;
		}
		.text1 {
			font-size: 11px;
			color: #fff;
			margin-bottom: 10px;
			font-weight: bold;
			letter-spacing: 3.5px;
		}
		.text2 {
			font-size: 26px;
			color: #fff;
			margin-bottom: 0;
			font-weight: 600;
		}
		.text3 {
			font-size: 11px;
			color: #fff;
			margin-bottom: 0;
		}
		.agent-time-block {
			margin-top: 20px;
		}
		.agent-time-block p {
			color: #fff;
			a {
				color: #fff;
			}
		}
		.agent-time-block .date-time > div {
			background: #fff;
		}
		.agent-time-block .date-time > div > div:first-child {
			color: $red;
		}
		.agent-time-block .date-time > div > div:last-child {
			color: $black;
		}
	}
}
.home-banner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;

	@media (max-width: 1024px) {
		position: relative;
	}

	.banner-item {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: bottom center;
		background-size: cover;

		@media (max-width: 1024px) {
			position: relative;
		}

		.img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@media (max-width: 1024px) {
				position: relative;
			}
		}
		.video-js {
			position: absolute;
		    top: 50%;
		    left: 50%;
		    transform: translate3d(-50%, -50%, 0);
		    opacity: 0;
		    &.vjs-playing {
		    	opacity: 1;
		    }
		}
	}
	.nav-item {
		position: absolute;
		right: 5%;
		top: 50%;
		transform: translateY(-50%);
		li {
			text-align: right;
			color: #fff;
			font-size: 15px;
			margin-bottom: 10px;
			cursor: pointer;
			&:last-child {
				margin-bottom: 0px;
			}
			span {
				margin-right: 7px;
			}
			i {
				font-size: 20px;
			}
			&.active, &:hover {
				color: #eb8923;
			}
		}
	}
}

.h-tour {
	padding-bottom: 79.2%;
	position: relative;
	.img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: no-repeat center;
		background-size: cover;
	}
	.layer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.layer1 {
		background-color: rgba(0,0,0,0.6);
		opacity: 1;
		visibility: visible;
		transition: all ease-in-out 0.2s;
	}
	&:hover {
		.layer1 {
			opacity: 0;
			visibility: hidden;
		}
	}
	.layer2 {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
	.text1 {
		margin: 0;
	}
	.text2 {
		margin: 0;
		font-size: 11px;
		margin-bottom: 15px;
		@include md {
			font-size: 10px;
			margin-bottom: 5px;
		}
	}
	.text3 {
		margin: 0;
		font-size: 22px;
		strong {
			font-weight: 600;
		}
		@include md {
			font-size: 20px;
		}
	}
	.text4 {
		margin: 0;
		font-size: 11px;
		color: #eb8921;
		margin-bottom: 10px;
		@include md {
			font-size: 10px;
			margin-bottom: 5px;
		}
	}
	.link2 {
		margin: 0;
		@include md {
			font-size: 10px;
		}
	}
}

.h-tour-list {
	>.row {
		margin: 0;
		> div {
			padding: 0;
		}
	}
}

.h-section1 {
	padding-bottom: 30px;
	@include sm2down {
		padding-bottom: 0;
	}
	.link2 {
		margin-top: 20px;
	}
}
.our-trip-we-love {
	background: #ebebeb;
	margin-bottom: 100px;
	@include sm2down {
		margin-bottom: 30px;
	}
	.container-fluid {
		padding-left: 0;
		padding-right: 0;
		>.row {
			margin-left: 0;
			margin-right: 0;
			> div {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	.h-agent5-list {
		&.row.agent5-list {
			margin-left: 0;
			margin-right: 0;
			> div {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 0;
			}
		}
		.img {
			padding-bottom: 110%;
		}
	}
	.our-trip-we-love-info {
		background: #ebebeb;
		width: 100%;
		padding: 0 30px;
		@include sm2down {
			padding: 0 15px;
		}
		.s-title {
			padding: 0;
			text-align: left;
		}

		.agent-block {
			padding-left: 120px;
			border-radius: 0;
			box-shadow: none;
			min-height: 120px;
			margin-bottom: 30px;
			@include sm2down {
				padding-left: 0;
			}
			.img {
				width: 120px;
				height: 120px;
				padding: 0;
				position: absolute;
				left: 0;
				z-index: 1;
			}
			.content {
				padding-left: 20px;
				padding-right: 0;
				padding-top: 0;
				padding-bottom: 0;
				overflow: hidden;
				@include sm2down {
					min-height: 120px;
					padding-left: 135px;
				}
			}
			.detail-box {
				background: #fff;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding-left: 140px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-top: 10px;
				opacity: 0;
				visibility: hidden;
				transform: translateY(-100%);
				transition: all ease-in-out 0.2s;
				@include sm2down {
					padding-left: 0;
					position: relative;
					transform: none;
					opacity: 1;
					visibility: visible;
					padding: 20px 0px 10px;
					background: none;
				}
				.link2 {
					font-size: 10px;
					padding: 8px 20px;
					margin-right: 15px;
					@include sm2down {
						margin-right: 2px;
						padding: 8px 10px;
					}
					i {
						margin-left: 5px;
						margin-right: 0;
					}
				}
			}
			&:hover {
				.detail-box {
					opacity: 1;
					visibility: visible;
					transform: translateY(0%);
				}
			}
		}
	}
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* page */
.banner {
	position: relative;
	min-height: 415px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: #fff;
	padding-top: 135px;
	@media (max-width: 1024px) {
		min-height: auto;
		padding: 0;
	}

	.img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;

		@media (max-width: 1024px) {
			position: relative;
		}
	}
	
	h3 {
		font-size: 14px;
		font-weight: bold;
		text-shadow: 5px 5px 10px rgb(0,0,0);
	}
	h2 {
		font-size: 26px;
		font-weight: bold;
		text-shadow: 5px 5px 10px rgb(0,0,0);
	}
}

.input {
	height: 40px;
	background: #6c6c6c;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	padding: 0 15px;
	border: 1px solid rgba(255,255,255,0.5);
	width: 100%;
	outline: none;
	@include placeholder {
		color: #fff;
	}
}
.input2 {
	height: 58px;
	background: none;
	color: #000;
	font-size: 14px;
	font-weight: normal;
	padding: 10px 20px;
	border: 2px solid #a0a0a0;
	width: 100%;
	border-radius: 10px;
	outline: none;
	@include placeholder {
		color: #959595;
	}
	@include xs {
		height: 45px;
	}
}
textarea.input2 {
	resize: none;
	height: 264px;
	@include xs {
		height: 150px;
	}
}
.submit {
	text-align: center;
	cursor: pointer;
}


.s-title {
	margin-top: 50px;
	margin-bottom: 30px;
	font-size: 26px;
	font-weight: 500;
	color: #000000;
	text-align: center;
	padding: 10px 35px;
	position: relative;
	display: inline-block;
	min-width: 310px;
	@include xs {
		min-width: 290px;
	}
	
	>span {
		display: block;
		font-size: 14px;
		color: $red;
		font-weight: bold;
		letter-spacing: 3.5px;
	}
	&.no-line {
		padding-left: 0;
		padding-right: 0;
		&::before, &::after {
			display: none;
		}
	}
	@include xs {
		font-size: 18px;
		padding: 10px 25px;
		margin-top: 30px;
		&::before, &::after {
			width: 70px;
		}
	}
}
.s-desc {
	margin: 0px;
}
.text-clicklink {
	font-size: 10px;
	color: $red;
}

.map-choose {
	position: relative;
	margin-top: 50px;
	margin-bottom: 50px;
	@include xs {
		margin-top: 30px;
	}
	> img {width: 100%;}
	.point {
		position: absolute;
		transform: translate3d(-50%, -50%, 0);
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: $red;
		cursor: pointer;
		transition: all ease 0.2s;
		&.square {
			border-radius: 0px;
		}
		&:hover {
			transform: translate3d(-50%, -50%, 0) scale(1.2);
		}
		.title {
			padding: 0px 0px 0px 15px;
    		font-weight: 600;
			position: relative;
			color: #fff;
		}
	}
}
#map-content {
	color: #fff;
	.s-title {
		color: #fff;
		margin-top: 100px;
		@include md2down {
			margin-top: 30px;
		}
	}
}
.des2-box {
	padding-bottom: 30px;
	.link2 {
		margin-top: 30px;
	}
}
.link2-group {
	margin: 30px 0;
}
.link2 {
	display: inline-block;
	font-size: 12px;
	color: #fff;
	font-weight: normal;
	padding: 5px 25px;
	border-radius: 14px;
	background: linear-gradient(-90deg, #4c0701, $red);
	transition: all ease 0.2s;
	margin-bottom: 10px;
	box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
	border: none;
	cursor: pointer;
	text-align: center;
	&:hover {
		box-shadow: 0 3px 10px rgba(0,0,0,0.2);
		color: #fff;
	}
	i {
		margin-left: 5px;
	}
}

.des-img-list {
	.item {
		padding-bottom: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
}

.d-gallery {
	margin-top: 25px;
	margin-bottom: 100px;
	overflow: hidden;
	@include xs {
		margin-top: 0px;
		margin-bottom: 30px;
	}
	.item {
		position: relative;
		width: 25%;
		float: left;
		background: #000;
		overflow: hidden;
		@include xs {
			width: 50% !important;
		}
		.img {
			padding-bottom: 100%;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			transition: all ease-out 0.2s;
			display: block;
			@include xs {
				padding-bottom: 100% !important;
			}
		}
		.title {
			font-size: 16px;
			font-weight: bold;
			color: #fff;
			text-align: center;
			text-shadow: 2px 2px 5px #000;
			padding: 0 25px;
		}
		.desc {
			font-size: 14px;
			font-weight: 500;
			color: #fff;
			text-align: justify;
			padding: 0 25px;
			@include lg {
				font-size: 12px;
				line-height: 16px;
			}
		}
		.layer1 {
			position: absolute;
			bottom: 0px;
			left: 0;
			width: 100%;
			transform: translateY(-20px);
			transition: all ease 0.2s;
		}
		.layer2 {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			visibility: hidden;
			opacity: 0;
			transform: translateY(-20px);
			transition: all ease 0.2s;
		}

		a.link {
			position: absolute;
		    top: 0;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    display: none;
		    @include md2down {
		    	display: block;
		    }
		}
		&.item1 {
			width: 25%;
			.img {
				padding-bottom: 200%;
			}
		}
		&.item2 {
			width: 25%;
		}
		&.item3 {
			width: 50%;
			.img {
				padding-bottom: 50%;
			}
		}
		&.item4 {
			width: 25%;
		}
		&:hover {
			.img {
				opacity: 0.5;
				transform: scale(1.2);
				transition: all ease-in 0.5s;
			}
			.layer1 {
				visibility: hidden;
				opacity: 0;
				transform: translateY(0px);
				transition: all ease 0.2s;
			}
			.layer2 {
				@include lg2up {
					visibility: visible;
					opacity: 1;
					transform: translateY(0px);
					transition: all ease 0.2s 0.1s;
				}
			}
		}
	}
}

.des2-container {
	max-width: 960px;
	margin: 0 auto;
	padding-bottom: 100px;
	@include sm2down {
		padding-top: 0;
		padding-bottom: 50px;
	}
}
.des2-block {
	.title {
		font-size: 26px;
		font-weight: 500;
		border-bottom: 0.5px solid #e3e3e3;
		padding-bottom: 5px;
		margin-top: 50px;
		strong {
			font-weight: 700;
		}
	}
	p {
		strong {
			font-weight: 600;
		}
	}
	.img-wrap {
		position: relative;
		margin-top: 50px;
		margin-bottom: 40px;
		@include sm2down {
			margin-top: 10px;
		}
		.img1, .img2 {
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			border-radius: 10px;
			box-shadow: 10px 10px 20px rgba(0,0,0,0.2);
		}
		.img2 {
			width: 70%;
			padding-bottom: 75%;
			position: relative;
			margin-left: 30%;
		}
		.img1 {
			width: 50%;
			padding-bottom: 60%;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.styles-container {
	max-width: 1000px;
	margin: 0 auto;
	padding-bottom: 100px;
	@include sm2down {
		padding-bottom: 30px;
	}
}
.style-block {
	.title {
		font-size: 26px;
		font-weight: 500;
		border-bottom: 0.5px solid #e3e3e3;
		padding-bottom: 5px;
		margin-top: 50px;
		@include xs {
			margin-top: 30px;
		}
		strong {
			font-weight: 700;
		}
	}
	p {
		text-align: justify;
		strong {
			font-weight: 600;
		}
	}
	.img-wrap {
		position: relative;
		margin-top: 50px;
		margin-bottom: 20px;
		margin-left: 80px;
		@include xs {
			margin-top: 10px;
			margin-bottom: 0px;
		}
		.img1, .img2 {
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			border-radius: 10px;
			box-shadow: 10px 10px 20px rgba(0,0,0,0.2);
		}
		.img2 {
			width: 100%;
			padding-bottom: 72.99%;
			position: relative;
		}
		.img1 {
			width: 50%;
		    padding-bottom: 60%;
		    position: absolute;
		    left: -80px;
		    top: 50%;
		    z-index: 1;
		    transform: translateY(-50%);
		    margin-top: 5px;
		}
	}
	.link2 {
		min-width: 145px;
	}
}
.styles-filter {
	border-bottom: 1px solid #dddddd;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	padding-bottom: 10px;
	position: relative;
	display: flex;
	justify-content: space-between;
	@include xs {
		border: none;
		margin-top: 30px;
		/*margin-bottom: 0px;*/
	}
	button {
		background: none;
		border: none;
		font-weight: 600;
		padding: 5px 12px;
		border-left: 1px solid #dddddd;
		cursor: pointer;
		position: relative;
		font-size: 18px;
		width: 100%;
		@include sm2down {
			font-size: 12px;
		}
		@include xs {
			border: none;
			padding: 5px;
		}
		&.active::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -10px;
			width: 100%;
			height: 3px;
			background: $red;
		}
		&:first-child {
			border: none;
		}
		&.mixitup-control-active {
			color: $red;
		}
	}
}

.res-container {
	max-width: 960px;
	margin: 0 auto;
	padding-bottom: 70px;
	@include xs {
		padding-bottom: 30px;
	}
}
.res-block {
	font-weight: 500;
	color: #000;
	margin-top: 50px;
	line-height: 18px;
	@include xs {
		margin-top: 30px;
	}
	.title {
		font-size: 16px;
		font-weight: bold;
		position: relative;
		margin-bottom: 15px;
		// &::after {
		// 	content: '';
		// 	position: absolute;
		// 	height: 2px;
		// 	background: $red;
		// 	width: 54px;
		// 	bottom: 4px;
    	// 	margin-left: 4px;
		// }
	}
	p {
		margin: 0px;
		text-align: justify;
	}
}

.section-gallery {
	.s-title {
		margin-top: 70px;
		@include xs {
			margin-top: 30px;
		}
	}
}

.responsible-gallery {
	max-width: 960px;
	margin: 0 auto;
}

.section-adventage {
	background: #ebebeb;
	padding-bottom: 100px;
	@include xs {
		padding-bottom: 30px;
	}
	.s-title {
		margin-top: 50px;
		@include xs {
			margin-top: 30px;
		}
	}
}
.about-container {
	max-width: 960px;
	margin: 0 auto;
	padding-bottom: 70px;
	@include xs {
		padding-bottom: 30px;
	}
}
.about-expertise-wrap {
	max-width: 960px;
	margin: 0 auto;
	overflow: hidden;
	margin-bottom: 100px;
	@include sm2down {
		margin-bottom: 30px;
	}
}

.about-gallery {
	margin-top: 0;
	margin-bottom: 0;
	.item {
		width: 33.3333333%;

		.desc {
			margin-bottom: 0;
			@include md2up {
				font-size: 11px;
				padding: 0 15px;
			}
		}
		&:hover {
			.layer2 {
				@include md2up {
					visibility: visible;
					opacity: 1;
					transform: none;
				}
			}
		}
	}
}
.adventages-container {
	max-width: 960px;
	margin: 0 auto;
	.row.adv-row {
		margin-left: -2px;
		margin-right: -2px;
		> div {
			padding-left: 2px;
			padding-right: 2px;
			margin-bottom: 4px;
		}
	}
	.res-block {
		margin-top: 0px;
		margin-bottom: 30px;
	}
}
.adventage-block {
	background: #fff;
	padding-bottom: 100%;
	position: relative;
	overflow: hidden;
	.content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 15px;
		.icon-check {
			margin-bottom: 25px;
			transform: translateY(10px);
			@include sm2down {
				width: 50px;
			}
		}
		.title {
			color: #2e2e2e;
			font-weight: bold;
			font-size: 15px;
			margin: 0;
			transform: translateY(10px);
			@include sm2down {
				font-size: 12px;
			}
		}
	}
	&:hover {
		background: linear-gradient(-90deg, #4c0701, $red);
		box-shadow: 5px 5px 20px rgba(0,0,0,0.5);
		transition: all ease 0.2s;
		.icon-check {
			opacity: 0;
			visibility: hidden;
			transition: all ease 0.2s;
		}
		.title {
			color: #fff;
			transform: translateY(0);
			transition: all ease 0.2s;
		}
	}
}

.expertise-container {
	margin-top: 50px;
	margin-bottom: 50px;
	@include sm2down {
		margin-top: 20px;
		margin-bottom: 0px;
	}
	> .row {
		margin-left: -7px;
		margin-right: -7px;
		> div {
			padding-left: 7px;
			padding-right: 7px;
			margin-bottom: 14px;
		}
	}
}
.expertise-block {
	position: relative;
	overflow: hidden;
	border: 1px solid #ccc;
	@include lg2down {
		font-size: 12px;
	}
	.img {
		padding-bottom: 107%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.chucvu {
		margin: 0px 0px 5px;
		font-size: 13px;
	}
	.name {
		font-weight: bold;
		font-size: 14px;
		margin: 0 0 5px;
	}
	.mota {
		margin: 0;
		line-height: 1.3;
		font-size: 13px;
	}
	.content {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		text-align: center;
		font-weight: 500;
		padding: 20px 10px;
		background: rgba(0,0,0,0.8);
		color: #fff;
		opacity: 0;
		visibility: hidden;
		transform: translateY(50%);
		transition: all ease-out 0.1s;
	}
	&:hover {
		.content {
			opacity: 1;
			visibility: visible;
			transform: translateY(0%);
			transition: all ease-in 0.2s;
		}
	}
}

.contact-container {
	padding-bottom: 50px;
	@include sm2down {
		padding-bottom: 10px;
	}
	label {
		font-weight: 600;
	}
	.input2 {
		margin-bottom: 20px;
	}
	.submit2 {
		width: 180px;
		max-width: 100%;
	}
}
.submit2 {
	display: inline-block;
	font-size: 12px;
	font-weight: bold;
	color: #fff;
	padding: 10px 25px;
	border-radius: 20px;
	background: linear-gradient(-90deg, #4c0701, $red);
	transition: all ease 0.2s;
	margin-bottom: 10px;
	cursor: pointer;
	border: none;
	&:hover {
		box-shadow: 5px 5px 20px rgba(0,0,0,0.5);
		color: #fff;
	}
	i {
		margin-right: 10px;
	}
}

.contact-map {
	height: 457px;
	position: relative;
	.map {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}

.contact-info {
	background: #ebebeb;
	padding: 30px 30px;
	@include md2down {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	h4 {
		font-weight: bold;
	}
}
.contact-avatar {
	width: 130px;
	height: 130px;
	border-radius: 50%;
	background: no-repeat center;
	background-color: #fff;
	background-size: cover;
	display: inline-block;
	margin-bottom: 20px;
}

.section-achievements {
	.img {
		padding-bottom: 119%;
		background: #2e2e2e;
		position: relative;
	}
	.img2 {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: no-repeat center;
		background-size: cover;
	}
}
.achievements-container {
	max-width: 960px;
	margin: 0 auto;
	padding-bottom: 60px;
	@include xs {
		padding-bottom: 30px;
	}
	.s-title {
		margin-top: 0px;
		@include xs {
			margin-bottom: 0px;
		}
	}
}

.achievement-block {
	border-left: 0.5px solid #ebebeb;
	position: relative;
	padding-top: 60px;
	@include xs {
		margin-top: 10px;
		padding-top: 30px;
	}
	.swiper-button-prev,.swiper-button-next {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: #b7b7b7;
		position: absolute;
		left: -10px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		margin: 0;
		&.swiper-button-disabled {
			opacity: 1;
		}
	}
	.swiper-button-prev {
		top: 0;
	}
	.swiper-button-next {
		bottom: 0;
		top: auto;
	}
}
.achievement-item {
	display: flex;
	cursor: pointer;
	.date {
		font-size: 12px;
		color: $red;
		font-weight: 500;
		border-bottom: 0.5px solid #ebebeb;
		padding: 0px 10px 2px;
		display: inline-block;
		margin-right: 15px;
		min-width: 110px;
		text-align: center;
		white-space: nowrap;
		@include xs {
			min-width: auto;
		}
	}
	.title {
		font-size: 15px;
		font-weight: bold;
		@include xs {
			margin-bottom: 5px;
			font-size: 13px;
		}
	}
	.desc {
		font-weight: 500;
		@include xs {
			font-size: 12px;
		}
	}
}

.swiper-achievement {
	width: 100%;
	height: 350px;
	.swiper-pagination {
		display: none;
	}
}

.agent-container {
	max-width: 960px;
	margin: 0 auto;
	padding-bottom: 0px;
	@include lg2down {
		padding-bottom: 50px;
	}
	@include sm2down {
		padding-bottom: 30px;
	}
	@include xs {
		padding-bottom: 30px;
	}
	.text1 {
		text-align: center;
		font-weight: bold;
		margin-top: 50px;
		margin-bottom: 15px;
		@include sm2down {
			margin-top: 30px;
			margin-bottom: 0px;
		}
	}
	.text2 {
		text-align: center;
		font-weight: bold;
		font-size: 26px;
		margin: 0 0 15px;
	}
	.text3 {
		text-align: center;
		font-weight: 500;
		margin-bottom: 70px;
		@include sm2down {
			margin-bottom: 30px;
		}
	}
}

.des1-agent {
	@include lg2up {
		padding-bottom: 100px;
	}
}
.agent-block {
	border-radius: 10px;
	box-shadow: 5px 5px 20px rgba(0,0,0,0.5);
	overflow: hidden;
	line-height: 1.2;
	position: relative;
	z-index: 1;
	margin-bottom: 40px;
	.img {
		padding: 0 0 74.44% 0;
		background: no-repeat center;
		background-size: cover;
	}
	.content {
		padding: 10px 30px 20px;
		position: relative;
		color: $black;
	}
	.cate {
		color: $red;
		font-size: 11px;
		margin-top: 5px;
		margin-bottom: 2px;
	}
	.title {
		font-size: 17px;
		font-weight: 600;
	}
	.date {
		font-size: 10px;
		margin-bottom: 5px;
		padding-left: 20px;
		position: relative;
		min-height: 16px;
		i.fa-dollar-sign {
			width: 15px;
			height: 15px;
			border-radius: 50%;
			display: inline-block;
			border: 1px solid $black;
			text-align: center;
			padding: 2px 0;
			font-size: 8px;
			margin-right: 5px;
			position: absolute;
			left: 0;
		}
		i.icon {
			font-size: 14px;
			margin-right: 5px;
			position: absolute;
			left: 0;
		}
	}
	.desc1 {
		font-size: 12px;
		position: relative;
		padding-left: 18px;
		border-bottom: 0.5px solid #f1f1f1;
		padding-bottom: 10px;
		margin-bottom: 10px;
		>i.fa {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			font-size: 14px;
			margin-top: -5px;
		}
	}
	.desc2 {
		font-weight: 12px;
		font-weight: 500;
	}
	.total-step {
		width: 53px;
		height: 53px;
		background-color: $red;
		border-radius: 10px;
		color: #fff;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		padding: 8px 0;
		position: absolute;
		right: 20px;
		top: -30px;
		span {
			display: block;
			font-size: 8px;
			font-weight: 500;
		}
	}
	&:hover + .agent-block-bottom {
		.btn-link {
			text-decoration: none;
			color: #fff;
			background: $red;
		}
	}
}
.agent-block-bottom {
	position: absolute;
	bottom: 0px;
	left: 15px;
	right: 15px;
	padding: 0 20px;
	z-index: 0;
	@include md {
		left: 5px;
		right: 5px;
		padding: 0;
	}
	.btn-link, .btn-link2 {
		font-size: 13px;
		font-weight: 600;
		text-align: center;
		padding: 10px 10px;
		text-transform: uppercase;
		color: $black;
		background: #dcdcdc;
		border-radius: 0 0 10px 10px;
		height: 40px;
		display: block;
		margin: 0 2px;
		@include md {
			font-size: 10px;
			height: 35px;
			border-radius: 20px;
		}
		@include xs {
			font-size: 11px;
			padding: 13px 10px;
		}
		&:hover {
			text-decoration: none;
			color: #fff;
			background: $red;
		}
	}
	.btn-link2 {
		background: #ccc;
	}
}

.agent-list-wrap {
	background-color: #ebebeb;
	padding: 30px 0 30px;
	margin-bottom: 100px;
}
.row.agent-list {
	margin-left: -7px;
	margin-right: -7px;
	> div {
		padding-left: 7px;
		padding-right: 7px;
		margin-bottom: 40px;
		@include sm2down {
			margin-bottom: 30px;
		}
	}
}

.agent-block2 {
	width: 100%;
	border-radius: 0px;
	line-height: 1.2;
	position: relative;
	z-index: 1;
	margin-bottom: 0px;
	@include xs {
		width: 100%;
	}
	.img {
		width: 100%;
		padding-bottom: 51.44%;
		border: 4px solid $red;
		background-position: center;
		background-repeat: no-repeat;
		background-size: auto 80%;
		&.img2 {
			padding-bottom: 100%;
		}
	}
	.content {
		padding: 10px 30px 0px;
		position: relative;
		color: $black;
		@include md2down {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
	.title {
		font-size: 17px;
		font-weight: bold;
		text-transform: uppercase;
		border-bottom: 1px solid #d5d5d5;
		padding-bottom: 5px;
	}
	.desc {
		margin-bottom: 26px;
		text-align: justify;
	}
	.link {
		background: $red;
		background: linear-gradient(to right, $red, #4c0701);
		color: #fff;
		text-align: center;
		border-radius: 15px;
		padding: 7px 15px;
		font-weight: bold;
		display: inline-block;
		font-size: 14px;
		transition: all ease 0.2s;
		&:hover {
			box-shadow: 0 3px 10px rgba(0,0,0,0.2);
		}
	}
}

.agent-block3 {
	position: relative;
	overflow: hidden;
	.img {
		padding-bottom: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		transition: all ease-out 0.2s;
	}
	.layer {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.layer1 {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		padding-top: 20%;
		background: rgba(0,0,0,0.5);
		.title {
			font-size: 22px;
			font-weight: bold;
			text-align: center;
			@include md2down {
				font-size: 18px;
			}
			@include sm2down {
				font-size: 14px;
			}
		}
	}
	.layer2 {
		background: $red;
		background: linear-gradient(to right, $red, #4c0701);
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		visibility: hidden;
		transition: all ease-out 0.2s;
	}
	.link {
		color: #fff;
		text-align: center;
		border-radius: 15px;
		padding: 7px 15px;
		font-weight: bold;
		display: inline-block;
		font-size: 14px;
		transition: all ease 0.2s;
		border: 1px solid #fff;
		&:hover {
			box-shadow: 0 3px 10px rgba(0,0,0,0.2);
		}
	}
	&:hover, &.active {
		.img {
			transform: scale(1.2);
			transition: all ease-in 0.3s;
		}
		.layer2 {
			opacity: 1;
			visibility: visible;
			transition: all ease-in 0.3s;
		}
	}
}
.agent7-container {
	max-width: 790px;
	margin: 0 auto;
	padding: 90px 0 70px;
	@include md2down {
		padding: 50px 0 30px;
	}
	@include xs {
		padding-top: 30px;
		padding-bottom: 10px;
	}
}
.row.agent7-list {
	margin-left: -10px;
	margin-right: -10px;
	>div {
		padding-left: 10px;
		padding-right: 10px;
		margin-bottom: 20px;
	}
}


.row.agent5-list {
	margin-left: -1px;
	margin-right: -1px;
	>div {
		padding-left: 1px;
		padding-right: 1px;
		margin-bottom: 2px;
	}
}
.agent-block5 {
	position: relative;
	overflow: hidden;
	cursor: pointer;
	.img {
		padding-bottom: 143.75%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		transition: all ease-out 0.2s;
	}
	.layer {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.layer1 {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: #fff;
		background: rgba(0,0,0,0.5);
		transition: all ease-out 0.2s;
		&::before {
			content: '';
			position: absolute;
			top: 0px;
			right: 0px;
			bottom: 0px;
			left: 0px;
			border: 1px solid #fff;
			opacity: 0;
			transition: all ease-out 0.2s;
		}
		.title {
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 3px;
			@include md2down {
				font-size: 16px;
			}
			@include sm2down {
				font-size: 14px;
			}
		}
		p {
			margin-bottom: 0;
			font-size: 12px;
		}
	}
	&:hover, &.active {
		.img {
			transition: all ease-in 0.3s;
			transform: scale(1.2);
		}
		.layer1 {
			transition: all ease-in 0.3s;
			background: none;
			&::before {
				opacity: 1;
				top: 10px;
				right: 10px;
				bottom: 10px;
				left: 10px;
				transition: all ease-in 0.3s;
			}
		}
	}
}

.agent4-container {
	max-width: 100%;
	margin: 0 auto;
	padding-bottom: 100px;
	@include md2down {
		padding-bottom: 50px;
	}
}

.style-block-agent {
	.title {
		border-bottom: none;
	}
	.discount {
		color: $red;
		font-size: 15px;
		font-weight: 600;
		border-bottom: 0.5px solid #e3e3e3;
		display: inline-block;
		padding-bottom: 10px;
		margin-bottom: 20px;
		min-width: 70%;
		strong {
			font-size: 17px;
		}
	}
	table {
		td:first-child {
			text-align: center;
			padding: 3px 5px 3px 0;
		}
	}
	.img-wrap {
		margin-left: 22%;
		margin-top: 20px;
		.img1 {
			width: 40%;
			padding-bottom: 40%;
			border-radius: 0px;
			left: -28%;
			margin-top: 0px;
		}
		.img2 {
			padding-bottom: 54.32%;
			border-radius: 0px;
		}
	}
	.link2 {
		margin-top: 15px;
	}
}
.agent-time-block {
	display: inline-block;
	margin-top: 50px;
	@include xs {
		margin-top: 30px;
	}
	p {
		font-size: 10px;
		color: #959595;
		padding-left: 10px;
		margin-bottom: 8px;
		text-align: left;
		a {
			color: #959595;
			font-size: 14px;
			margin-left: 5px;
			&:hover {
				color: $red;
			}
		}
	}
	.date-time {
		display: flex;
		> div {
			border: 1px solid #959595;
			margin-right: 8px;
			border-radius: 8px;
			min-width: 40px;
			text-align: center;
			color: #959595;
			padding: 4px 0;
			>div:first-child {
				font-size: 20px;
				line-height: 1;
			}
			>div:last-child {
				font-size: 9px;
			}
		}
	}
}

.section-faq {
	padding-bottom: 100px;
	@include sm2down {
		padding-bottom: 30px;
	}
	.faq-label {
		margin-top: 50px;
		margin-bottom: 30px;
		font-size: 20px;
		overflow: hidden;
		font-weight: bold;
		@include xs {
			font-size: 16px;
			margin-top: 30px;
			margin-bottom: 15px;
			.link2 {
				font-size: 10px;
				padding: 5px 10px;
			}
		}
	}
	.s-title {
		@include xs {
			margin-bottom: 0;
		}
	}
}
.faq-list {
	li {
		.faq-question {
			color: $black;
			font-size: 13px;
			font-weight: bold;
			border: none;
			border-top: 1px solid #959595;
			padding: 20px 20px;
			display: block;
			position: relative;
			@include xs {
				padding: 15px 20px;
			}
			&[aria-expanded="true"] {
				border-top-color: transparent;
				background: #ebebeb;
				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 3px;
					height: 100%;
					background: $red;
				}
			}
			i {
				background: #ebebeb;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				text-align: center;
				color: #fff;
				position: absolute;
				right: 10px;
			}
		}
		&:last-child {
			.faq-question {
				border-bottom: 1px solid #959595;
				&[aria-expanded="true"] {
					border-bottom-color: transparent;
				}
			}
		}
		.faq-answer {
			position: relative;
			background: #ebebeb;
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 3px;
				height: 100%;
				background: $red;
				z-index: 1;
			}
			.card {
				background: none;
				border:none;
				border-top: 1px solid #fff;
				font-size: 13px;
			}
		}
	}
}

.section-cart {
	background: #ebebeb;
}
.cart-container {
	max-width: 960px;
	margin: 0 auto;
	margin-bottom: 100px;
	padding-bottom: 50px;
	@include sm2down {
		margin-bottom: 30px;
		padding-bottom: 30px;
	}
	.s-title {
		padding: 0;
		strong {
			display: block;
			margin-bottom: 15px;
		}
	}
	.input2 {
		background: #fff;
		border: none;
		border-radius: 5px;
		margin-bottom: 30px;
	}
	textarea.input2 {
		height: 160px;
	}
	.submit2 {
		min-width: 150px;
	}
}
.order-summary-container {
	max-width: 960px;
	margin: 0 auto;
	.s-title {
		padding: 0;
	}
}
.section-registration-time {
	padding: 80px 0 40px;
	@include md {
		padding: 40px 0 40px;
	}
	@include sm2down {
		padding: 40px 0 20px;
	}
	.text1 {
		color: #6c6c6c;
		margin: 0;
		font-size: 20px;
		font-weight: 600;
		span {
			color: $red;
		}
	}
	.text2 {
		color: #6c6c6c;
		font-size: 13px;
	}
}
.section-registration-container {
	max-width: 960px;
	margin: 0 auto;
}

.timer-block {
	.timer-item {
		text-align: center;
		border-radius: 5px;
		box-shadow: 5px 2px 10px rgba(0,0,0,0.2);
		display: inline-block;
		width: 60px;
		margin-left: 10px;
		padding: 8px 0;
		.number {
			color: $red;
			font-size: 30px;
			margin: 0;
			line-height: 1;
			letter-spacing: 2px;
		}
		.text {
			color: $black;
			font-size: 14px;
			margin: 0;
		}
	}
}

.order-summary-block {
	background: #fff;
	padding: 20px 30px 10px;
	border-radius: 5px;
	.order-summary-table {
		overflow: hidden;
		overflow-x: auto;
		table {
			min-width: 800px;
		}
	}
	table {
		width: 100%;
		th {
			vertical-align: top;
			color: $red;
			font-size: 16px;
			font-weight: 600;
			p {
				font-size: 10px;
				color: #6c6c6c;
			}
		}
		td {
			vertical-align: top;
			p {
				font-size: 12px;
				margin-bottom: 0;
			}
		}
	}
	.bottom {
		margin: 10px 0;
		background: linear-gradient(-90deg, #4c0701, $red);
		border-radius: 5px;
		color: #fff;
		padding: 12px 12px;
		p {
			margin: 0;
			font-weight: 600;
			@include xs {
				margin-top: 5px;
				margin-bottom: 5px;
			}
		}
		input {
			padding: 2px 15px;
			width: 100%;
			border-radius: 2px;
			border: none;
		}
		.text-total {
			font-size: 16px;
		}
	}
}

.pagination {
	margin-top: 30px;
	.page-item {
		.page-link {
			color: #959595;
			background: none;
			border: none;
			font-size: 20px;
			box-shadow: none;
			font-weight: 600;
		}
		&.active {
			.page-link {
				color: $black;
			}
		}
		&.prev, &.next {
			.page-link {
				font-size: 12px;
				margin-top: 7px;
				i {
					margin: 0 5px;
				}
			}
		}
	}
}

.travel-style-select {
	/*height: 50px;*/
	padding: 0px 30px;
	position: relative;
	max-width: 960px;
	margin: 0 auto;
	margin-bottom: 40px;
	&::after {
		content: '';
		position: absolute;
		height: 21px;
		width: 1px;
		top: 0;
		right: 30px;
		background: #fff;
		z-index: 1;
	}
	.item {
		text-align: center;
		border-right: 1px solid #959595;
		a {
			color: #959595;
			i {
				margin-right: 5px;
			}
			&.active, &:hover {
				color: $black;
				i {
					color: $red;
				}
			}
		}
	}
	.swiper-calendar-nav {
		position: absolute;
		top: 0;
		width: 20px;
		height: 20px;
		text-align: center;
		border-radius: 50%;
		background: #959595;
		color: #fff;
		cursor: pointer;
		&.left {
			left: 0;
		}
		&.right {
			right: 0;
		}
	}
}

.travel-style-filter-container {
	background: #ebebeb;
}
.travel-style-filter-wrap {
	padding: 0px 30px;
	position: relative;
	max-width: 960px;
	margin: 0 auto;
}
.travel-style-filter {
	background: #fff;
	border-radius: 0 0 20px 20px;
	padding: 0px 50px 20px;
	position: relative;
	z-index: 2;
	@include sm2down {
		padding: 0px 15px 20px;
		border-radius: 0 0 5px 5px;
	}
	label {
		white-space: nowrap;
		margin-right: 5px;
		font-size: 12px;
		margin-top: 3px;
		margin-bottom: 0;
		@include sm2down {
			width: 100%;
			margin-right: 0;
			margin-top: 10px;
			margin-bottom: 5px;
		}
	}
	.input-filter {
		position: relative;
		input {
			width: 100%;
			padding: 2px 15px 2px 10px;
			font-size: 12px;
		}
		select {
			width: 100%;
			padding: 2px 15px 2px 10px;
			appearance: none;
			-webkit-appearance: none;
			font-size: 12px;
			border-radius: 0;
			color: $black;
			width: 180px;
			background: none;
			outline: none;
		}
		i {
			position: absolute;
			right: 6px;
			top: 6px;
			color: #959595;
			pointer-events: none;
		}
	}
	
}
.travel-style-list {
	background: #fff;
	padding: 0px 50px 40px;
	position: relative;
	z-index: 2;
	@include sm2down {
		padding: 0px 15px 0px;
	}
	a.label {
		font-size: 14px;
		color: #959595;
		font-weight: 400;
		&.active {
			color: $black;
		}
	}
	a:not(.label) {
		color: #959595;
		position: relative;
		padding-left: 15px;
		margin-right: 10px;
		font-size: 12px;
		&.active {
			color: $black;
			font-weight: bold;
		}
		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 5px;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background: #959595;
		}
	}
}

.cart2-info {
	margin-top: 50px;
}
.cart2-item {
	position: relative;
	padding-bottom: 73.58%;
	border-radius: 10px;
	box-shadow: 0 3px 10px rgba(0,0,0,0.3);
	margin-bottom: 20px;
	.layer1 {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		background: rgba($black, 0.4);
		border-radius: 10px;
		transition: all ease 0.2s;
	}
	&:hover {
		.layer1 {
			opacity: 0;
			visibility: hidden;
		}
	}
	.content {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		color: #fff;
		padding: 10px 20px 15px;
		font-size: 12px;
		h4 {
			font-size: 19px;
		}
		p {
			margin: 0;
		}
		.price {
			margin-bottom: 2px;
			i {
				width: 15px;
				height: 15px;
				border-radius: 50%;
				display: inline-block;
				border: 1px solid #fff;
				text-align: center;
				font-size: 10px;
				padding: 1px;
				margin-right: 8px;
			}
		}
		.location {
			i {
				font-size: 14px;
				margin: 0 10px 0px 2px;
			}
		}
	}
	a.link2 {
		position: absolute;
		bottom: -14px;
		right: 20px;
		margin: 0;
	}
}

.swiper-cart2-wrap {
	position: relative;
	.swiper-button-prev {
		left: -40px;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23FFFFFF'%2F%3E%3C%2Fsvg%3E");
	}
	.swiper-button-next {
		right: -40px;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23FFFFFF'%2F%3E%3C%2Fsvg%3E");
	}
	.swiper-button-disabled {display: none;}
}

.cart2modal .modal-content {
	background: rgba($black, 0.9);
	color: #fff;
	border-radius: 10px;
	.modal-footer {
		border: none;
		padding-top: 0;
	}
}

.cart-select {
	position: relative;
	display: inline-block;
	select {
		appearance: none;
		-webkit-appearance: none;
		border: none;
		background: none;
		padding: 0 15px 0 0;
		outline: none;
	}
	i {
		position: absolute;
		top: 4px;
		right: 0;
		pointer-events: none;
		color: rgba($black, 0.5);
	}
}

.des5-date-price {
	background: #ebebeb;
}
.des5-date-price-container {
	max-width: 960px;
	margin: 0 auto;
	padding-bottom: 50px;
	.row {
		margin: 0;
		> div {
			padding: 0;
		}
	}
}
.des5-list {
	background: #fff;
	padding-top: 0;
}
.swiper-date-price-wrap {
	position: relative;
	margin-top: 60px;
	@include md2down {
		padding: 0 30px;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.swiper-button-prev {
		width: 20px;
		height: 20px;
		background-size: contain;
		margin-top: -10px;
		@include lg2up {
			top: -20px;
			left: 50%;
			transform: translateX(-50%) rotate(90deg);
			margin: 0;
		}
	}
	.swiper-button-next {
		width: 20px;
		height: 20px;
		background-size: contain;
		margin-top: -10px;
		@include lg2up {
			top: auto;
			bottom: -20px;
			right: auto;
			left: 50%;
			transform: translateX(-50%) rotate(90deg);
			margin: 0;
		}
	}
}
.swiper-date-price {
	height: 300px;
	overflow: hidden;
	border-radius: 5px 0 0 5px;
	background: #959595;
	@include md2down {
		height: auto;
		border-radius: 5px;
	}
	.item {
		height: 100%;
		background: #959595;
		color: #fff;
		border-bottom: 1px solid rgba(#fff, 0.5);
		a {
			color: #fff;
			padding: 5px 10px;
		    height: 100%;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    text-align: center;
		    &.active {
				background: $red;
			}
		}
	}
}
.des5-table-wrap {
	position: relative;
	height: 100%;
	&::before {
		content: '';
		top: 60px;
		left: 0;
		right: 0;
		bottom: 0;
		position: absolute;
		background: #fff;
		border-radius: 10px;
	}
	.dateprice-table {
		min-height: 450px;
		overflow: hidden;
		overflow-x: auto;
		@include md2down {
			min-height: auto;
		}
	}
	table {
		width: 100%;
		position: relative;
		@include md2down {
			min-width: 730px;
		}
		th {
			padding: 20px 20px;
			text-align: center;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				right: 0;
				width: 1px;
				height: 20px;
				border-right: 1px solid $black;
			}
			&:last-child {
				&::after {
					display: none;
				}
			}
		}
		td {
			padding: 14px 20px;
			color: #6c6c6c;
			border-bottom: 1px solid #6c6c6c;
			text-align: center;
			@include md2down {
				padding: 5px 15px;
			}
		}
		tr:last-child {
			td {
				border-bottom: none;
			}
		}
	}
	.link2 {
		min-width: 130px;
		margin-bottom: 0;
	}
}

.section-des5-map {
	.s-title {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	p {
		margin: 0;
	}
}
.des5-map-container {
	max-width: 960px;
	margin: 0 auto;
	.map {
		height: 350px;
		margin-top: 30px;
	}
}
.des5-tab-container {
	max-width: 700px;
	margin: 0 auto;
	.nav-tabs {
		max-width: 600px;
		margin: 0 auto;
		margin-top: 50px;
		margin-bottom: 20px;
		.nav-item {
			margin: 0;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				top: 9px;
				right: 0px;
				width: 1px;
				height: 18px;
				border-right: 1px solid #959595;
			}
			&:last-child {
				&::after {
					display: none;
				}
			}
		}
		a.nav-link {
			color: #959595;
			font-weight: 600;
			border: none;
			margin: 0;
			&.active {
				color: $red;
			}
		}
	}
	.tab-content {
		margin-bottom: 50px;
		padding: 0 0px;
		.link2 {
			margin: 0 5px 10px;
		}
	}
}
.overview-list {
	max-width: 600px;
	margin: 0 auto;
	margin-bottom: 30px;
	li {
		background: url(../img/icon-check.png) no-repeat left center;
		padding-left: 30px;
		margin-bottom: 10px;
	}
}

.itinerary-label-wrap {
	max-width: 600px;
	margin: 0 auto;
	margin-bottom: 10px;
	.itinerary-label {
		position: relative;
		color: #ccc;
		padding-right: 20px;
		margin-left: 30px;
		i {
			position: absolute;
			right: 0px;
			top: 3px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background: #959595;
			color: #fff;
			text-align: center;
			transition: transform ease 0.1s;
		}
	}
}
.itinerary-list {
	max-width: 600px;
	margin: 0 auto;
	> li {
		margin-bottom: 20px;
		a.faq-question {
			display: block;
			background: #ebebeb;
			color: $black;
			padding: 5px 15px;
			position: relative;
			i {
				position: absolute;
				right: 10px;
				top: 9px;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				background: #959595;
				color: #fff;
				text-align: center;
				transition: transform ease 0.1s;
			}
			&[aria-expanded="true"] {
				i {
					transform: rotate(180deg);
				}
			}
		}
		.faq-answer {
			.card {
				border: none;
			}
		}
	}
}
.des5-policies {
	max-width: 600px;
	margin: 0 auto;
}
.meal-list {
	margin-bottom: 15px;
	>li {
		padding-left: 50px;
		position: relative;
		min-height: 32px;
		margin-bottom: 8px;
		padding-top: 5px;
		img {
			width: 32px;
			height: 32px;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.des5-gallery {
	.row {
		margin-left: -5px;
		margin-right: -5px;
		> div {
			padding-left: 5px;
			padding-right: 5px;
			margin-bottom: 10px;
		}
	}
	.agent-block3 {
		.img {
			padding-bottom: 78.12%;
		}
	}
}

.promotion-code {
	position: relative;
	.icon-check {
		position: absolute;
		top: 6px;
		right: 5px;
		color: green;
		pointer-events: none;
	}
}

.cart-loading {
	position: fixed;
	width: 100px;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
}

.agent-modal {
	.modal-lg {
		max-width: 660px;
	}
	.modal-content {
		background: #ebebeb;
		.modal-header {
			padding-top: 20px;
			padding-bottom: 0;
			padding-left: 50px;
			padding-right: 50px;
			@include sm2down {
				padding-top: 10px;
				padding-left: 20px;
				padding-right: 20px;
			}
			.modal-title {
				font-size: 23px;
				color: $red;
				font-weight: 500;
				line-height: 1.2;
    			margin-bottom: 10px;
			}
		}
		.link2 {
			margin: 0 0 30px;
			@include sm2down {
				margin-bottom: 20px;
			}
		}
	}
	.modal-body {
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 50px;
		padding-right: 50px;
		@include sm2down {
			padding-left: 20px;
			padding-right: 20px;
			.row {
				margin-left: -5px;
				margin-right: -5px;
				>div {
					padding-left: 5px;
					padding-right: 5px;
				}
			}
		}
		.desc {
			font-size: 12px;
			line-height: 1.2;
		}
		label {
			margin-bottom: 5px;
			font-weight: 500;
		}
		.form-input {
			width: 100%;
			border-radius: 5px;
			border: 1px solid #808080;
			background: none;
			padding: 6px 15px;
			margin-bottom: 20px;
			height: 35px;
			outline: none;
		}
		textarea.form-input {height: 130px; resize: none;}
		.select-wrap {
			position: relative;
			div {
				position: absolute;
			    top: 0;
			    right: 0;
			    height: 35px;
			    border: 1px solid #808080;
			    border-left-color: transparent;
			    border-radius: 0 5px 5px 0;
			    background: #ebebeb;
			    display: flex;
			    justify-content: center;
			    align-items: center;
			    padding-right: 5px;
				color: #808080;
				pointer-events: none;
			}
		}
	}
}


/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}