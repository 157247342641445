/* follow bootstrap 4 */

/* size */
$xl: 1200px; //min val
$lg: 992px; //min val
$md: 768px; //min val
$sm: 576px; //min val

@mixin xl {
    @media (min-width: $xl) {
        @content;
    }
}
@mixin lg {
    @media (min-width: $lg) and (max-width: ($xl)-1) {
        @content;
    }
}
@mixin md {
    @media (min-width: $md) and (max-width: ($lg)-1) {
        @content;
    }
}
@mixin sm {
    @media (min-width: $sm) and (max-width: ($md)-1) {
        @content;
    }
}
@mixin xs {
    @media (max-width: ($sm)-1) {
        @content;
    }
}

/* sm up & down */
@mixin sm2up {
    @media (min-width: $sm) {
        @content;
    }
}
@mixin sm2down {
    @media (max-width: ($md)-1) {
        @content;
    }
}
/* md up & down */
@mixin md2up {
    @media (min-width: $md) {
        @content;
    }
}
@mixin md2down {
    @media (max-width: ($lg)-1) {
        @content;
    }
}
/* lg up & down */
@mixin lg2up {
    @media (min-width: $lg) {
        @content;
    }
}
@mixin lg2down {
    @media (max-width: ($xl)-1) {
        @content;
    }
}